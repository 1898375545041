.sold{
    background-color: red;
}

.available{
    background-color: whitesmoke;
}

.discount{
    color: green
}

.beforeprice{
    color: red;
}